<template>
  <div class="mx-3 mx-md-6">
    <v-row align="center">
      <v-col cols="12" class="pb-0">
        <div class="mt-6 headline">All Restaurants </div>
      </v-col>
      <v-col class="">
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-food"
          prefix=" | "
          clearable
          label="Search by Restaurant"
          single-line
          class="search-filter"
          hide-details
          @keyup.enter="searchFilter(1)"
        ></v-text-field>
      </v-col>
      <v-col class="">
        <v-text-field
          v-model="searchArea"
          prepend-inner-icon="mdi-map-marker-radius"
          prefix=" | "
          clearable
          label="Search by Area"
          single-line
          class="search-filter"
          hide-details
          @keyup.enter="searchFilter(2)"
        ></v-text-field>
      </v-col>
      <v-col class="">
        <v-text-field
          v-model="searchBy"
          prepend-inner-icon="mdi-account"
          prefix=" | "
          clearable
          label="Onboarded by"
          single-line
          class="search-filter"
          hide-details
          @keyup.enter="searchFilter(3)"
        ></v-text-field>
      </v-col>
      <v-col class="">
        <v-select
          v-model="filter"
          prepend-inner-icon="mdi-tune"
          prefix=" | "
          :items="filters"
          label="Filter by"
          single-line
          :disabled="loading"
          class="search-filter"
          hide-details
          @change="page = 1, filterTenant()"
        ></v-select>
      </v-col>
      <v-col cols="12">
        <div class="text-caption text--secondary">
          <v-icon size="16px">mdi-information-outline</v-icon>
          Last Updated: {{ analytics.lastUpdatedAt ? new Date(analytics.lastUpdatedAt).toUTCString() : lastUpdatedAt }}
        </div>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col :cols="$vuetify.breakpoint.xs ? 6 : ''">
        <v-card style="background-color: #151d21;">
          <v-card-text class="caption text-uppercase pa-3 pb-1 grey-text">
            TOTAL
          </v-card-text>
          <v-card-title class="text-h6 pa-3 pt-0 blue-text" text-color="#2566AF">
            {{ analytics.totalTenant }}
          </v-card-title>
        </v-card>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.xs ? 6 : ''">
        <v-card style="background-color: #151d21;">
          <v-card-text class="caption text-uppercase pa-3 pb-1" style="color: #66d1a5">
            ACTIVE
          </v-card-text>
          <v-card-title class="text-h6 pa-3 pt-0" style="color: #66d1a5">
            {{ analytics.activeTenant }}
          </v-card-title>
        </v-card>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.xs ? 6 : ''">
        <v-card style="background-color: #151d21;">
          <v-card-text class="caption text-uppercase pa-3 pb-1" style="color: #66d1a5">
            ACTIVE POS
          </v-card-text>
          <v-card-title class="text-h6 pa-3 pt-0" style="color: #66d1a5">
            {{ analytics.activeBranch }}
          </v-card-title>
        </v-card>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.xs ? 6 : ''">
        <v-card style="background-color: #151d21;">
          <v-card-text class="caption text-uppercase pa-3 pb-1" style="color: rgba(254, 243, 199, 1)">
            PARTIALLY ACTIVE
          </v-card-text>
          <v-card-title class="text-h6 pa-3 pt-0" style="color: rgba(254, 243, 199, 1)">
            {{ analytics.partiallyActiveTenant }}
          </v-card-title>
        </v-card>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.xs ? 6 : ''">
        <v-card style="background-color: #151d21;">
          <v-card-text class="caption text-uppercase pa-3 pb-1" style="color: rgba(254, 226, 226, 1)">
            INACTIVE
          </v-card-text>
          <v-card-title class="text-h6 pa-3 pt-0" style="color: rgba(254, 226, 226, 1)">
            {{ analytics.inactiveTenant }}
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <!-- <v-data-table
      :loading="loading"
      :headers="headers"
      :items="credentials"
      :search="search"
      class="elevation-1 my-6"
      style="background-color: #151d21; cursor: pointer"
      @click:row="viewTenant($event)"
    >
    </v-data-table> -->

    <div v-if="production">
      <v-data-table
        :loading="loading"
        :headers="headers"
        :items="allTenant"
        hide-default-footer
        class="elevation-1 my-6 table-hover"
        @click:row="viewTenant($event)"
      >
        <template v-slot:[`item.name`]="{ item }">
          {{ item.name }}
          <span v-if="statusfilter">({{ item.branch }})</span>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <div v-if="statusfilter">
            <v-chip-group>
              <v-card v-if="filter === 'Inactive'" flat class="rounded-md px-3 py-0" color="rgba(254, 226, 226, 1)">
                <span style="color: rgba(127, 29, 29, 1)" class="text-body-1">Last active: {{ item.last_order_date_time }}</span>
              </v-card>
              <v-card v-else flat class="rounded-md px-3 py-0" color="#66d1a5">
                <span class="text-body-1 black--text">Active</span>
              </v-card>
            </v-chip-group>
          </div>
          <div v-else>
            <v-chip-group v-if="item.active_branch === 0">
              <v-card flat class="rounded-md px-3 py-0" color="rgba(254, 226, 226, 1)">
                <span style="color: rgba(127, 29, 29, 1)" class="text-body-1">Inactive</span>
              </v-card>
            </v-chip-group>
            <v-chip-group v-else-if="item.active_branch === item.total_branch">
              <v-card flat class="rounded-md px-3 py-0" color="#66d1a5">
                <span class="text-body-1 black--text">Active</span>
              </v-card>
            </v-chip-group>
            <v-chip-group v-else>
              <v-card flat class="rounded-md px-3 py-0" color="rgba(254, 243, 199, 1)">
                <span style="color: rgba(120, 53, 15, 1)" class="text-body-1">
                  Partially active ({{ item.active_branch }}/{{ item.total_branch }})
                </span>
              </v-card>
            </v-chip-group>
          </div>
        </template>
        <template v-slot:[`item.onboarded_by`]="{ item }">
          <span>{{ item.onboarded_by ? item.onboarded_by : 'None' }}</span>
        </template>
        <template v-slot:[`item.onboarding_date`]="{ item }">
          <span>{{ item.onboarding_date && item.onboarding_date !== null ? $date(item.onboarding_date) : 'None' }}</span>
        </template>
        <template v-slot:[`item.actions`]>
          <v-icon class="eyecon" color="transparent">mdi-eye-outline</v-icon>
        </template>
      </v-data-table>
      <div class="text-center pt-2">
        <v-pagination
          v-model="page"
          :length="pageCount"
          @input="getPaged"
        ></v-pagination>
      </div>
    </div>
    <div v-else>
      <v-row class="mb-7 mt-n5 ma-0">
        <v-col cols="12">
          <v-fab-transition>
          <v-btn
            @click.stop="dialogDelete = true"
            v-show="!hidden"
            text
            fab
            dark
            small
            absolute
            class="ml-n1"
          >
            <v-icon>mdi-delete-outline</v-icon>
          </v-btn>
        </v-fab-transition>
        </v-col>
      </v-row>
      <v-dialog v-model="dialogDelete" max-width="510px">
        <v-card>
          <v-card-title class="headline"
            >Are you sure you want to delete this item?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary darken-1" text @click="dialogDelete = false"
              >Cancel</v-btn
            >
            <v-btn color="primary darken-1" text @click="deleteItemConfirm"
              >OK</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-data-table
        v-model="selected"
        :loading="loading"
        :headers="headers"
        :items="allTenant"
        show-select
        hide-default-footer
        class="elevation-1 mb-6 table-hover"
        @click:row="viewTenant($event)"
      >
        <template v-slot:[`item.name`]="{ item }">
          {{ item.name }}
          <span v-if="statusfilter">({{ item.branch }})</span>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <div v-if="statusfilter">
            <v-chip-group>
              <v-card v-if="filter === 'Inactive'" flat class="rounded-md px-3 py-0" color="rgba(254, 226, 226, 1)">
                <span style="color: rgba(127, 29, 29, 1)" class="text-body-1">Last active: {{ item.last_order_date_time }}</span>
              </v-card>
              <v-card v-else flat class="rounded-md px-3 py-0" color="#66d1a5">
                <span class="text-body-1 black--text">Active</span>
              </v-card>
            </v-chip-group>
          </div>
          <div v-else>
            <v-chip-group v-if="item.active_branch === 0">
              <v-card flat class="rounded-md px-3 py-0" color="rgba(254, 226, 226, 1)">
                <span style="color: rgba(127, 29, 29, 1)" class="text-body-1">Inactive</span>
              </v-card>
            </v-chip-group>
            <v-chip-group v-else-if="item.active_branch === item.total_branch">
              <v-card flat class="rounded-md px-3 py-0" color="#66d1a5">
                <span class="text-body-1 black--text">Active</span>
              </v-card>
            </v-chip-group>
            <v-chip-group v-else>
              <v-card flat class="rounded-md px-3 py-0" color="rgba(254, 243, 199, 1)">
                <span style="color: rgba(120, 53, 15, 1)" class="text-body-1">
                  Partially active ({{ item.active_branch }}/{{ item.total_branch }})
                </span>
              </v-card>
            </v-chip-group>
          </div>
        </template>
        <template v-slot:[`item.actions`]>
          <v-icon class="eyecon" color="transparent">mdi-eye-outline</v-icon>
        </template>
        <template v-slot:[`item.onboarded_by`]="{ item }">
          <span>{{ item.onboarded_by ? item.onboarded_by : 'None' }}</span>
        </template>
        <template v-slot:[`item.onboarding_date`]="{ item }">
          <span>{{ item.onboarding_date && item.onboarding_date !== null ? $date(item.onboarding_date) : 'None' }}</span>
        </template>
      </v-data-table>
      <div class="text-center pt-2">
        <v-pagination
          v-model="page"
          :length="pageCount"
          @input="getPaged"
        ></v-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { eventBus } from "../../main";
import store from "../../store";
export default {
  data() {
    return {
      headers: [
        {
          text: "Restaurant",
          align: "start",
          sortable: true,
          value: "name",
        },
        { text: "Status", value: "status", sortable: true },
        { text: "Onboarded By", value: "onboarded_by", sortable: true },
        {
          text: "Onboarding Date",
          value: "onboarding_date",
          sortable: true,
          align: "end",
          filterable: false,
        },
        { text: 'Actions', value: 'actions', align: "end", },
      ],
      credentials: [],
      allTenant: [],
      singleTenant: '',
      searchArea: '',
      searchBy: '',
      search: '',
      searchPayload: '',
      loading: true,
      statusfilter: false,
      analytics: {
        activeTenant: 0,
        inactiveTenant: 0,
        partiallyActiveTenant: 0,
        totalTenant:0,
        activeBranch: 0,
        lastUpdatedAt: new Date()
      },
      production: true,
      selected: [],
      ids: [],
      hidden: true,
      page: 1,
      pageCount: 1,
      dialogDelete: false,
      filter: '',
      filters: [ 'All', 'Active', 'Inactive']
    };
  },

  watch: {
    selected(val) {
      // console.log("selected items watch", val)
      if (val.length >= 1) {
        this.hidden = false
      } else {
        this.hidden = true
      }
    },
  },

  created(){
    // checking if production
    if (process.env.VUE_APP_MODE == "production") {
      // console.log("its production");
      this.production = true;
    } else {
      // console.log("checking env for delete - ", process.env.VUE_APP_MODE);
      this.production = false;
    }
  },
  beforeMount() {
    this.getPaged()
    this.getTenantAnalytics()
  },
  methods: {
    searchFilter (type) {
      this.statusfilter = false
      this.filter = ''
      this.searchPayload = ''
      if (type === 1) {
        this.searchPayload = this.search
        this.searchBy = ''
        this.searchArea = ''
      } else if (type === 3) {
        this.searchPayload = this.searchBy
        this.searchArea = ''
        this.search = ''
      } else {
        this.search = ''
        this.searchBy = ''
        this.searchPayload = this.searchArea
      }
      if (this.searchPayload === null) {
        this.searchPayload = ''
      }
      const payload = {
        search: this.searchPayload,
        page: 1
      }
      this.getTenantList(payload)
    },
    getTenantList (payload) {
      this.loading = true
      if (payload.search === '') {
        store.dispatch('getAllTenantsPaged', payload)
        .then((res) => {
          // console.log(res.data.data)
          this.pageCount = res.data.data.tenants.last_page
          this.allTenant = res.data.data.tenants.data
          this.loading = false
        }).catch(err => {
            console.log(err)
            this.loading = false
            eventBus.$emit('snackbar', {
              snackbarColor: "#FF5252",
              snackbarText: "Something Went Wrong!",
            })
          })
      } else {
        store.dispatch('getAllTenantsFilter', payload)
        .then((res) => {
          // console.log(res.data.data)
          this.pageCount = res.data.data.tenants.last_page
          this.allTenant = res.data.data.tenants.data
          this.loading = false
        }).catch(err => {
            console.log(err)
            this.loading = false
            eventBus.$emit('snackbar', {
              snackbarColor: "#FF5252",
              snackbarText: "Something Went Wrong!",
            })
          })
      }
    },
    deleteItemConfirm() {
      this.dialogDelete = false
      this.loading = true
      this.selected.forEach((tenant) => {
        this.ids.push(tenant.id)
      });
      // console.log("deleted", this.selected)
      // console.log("selected restaurant ids array", this.ids)
      // console.log('table list',this.credentials)
      store.dispatch('deleteRestaurant', this.ids)
      .then(res => {
        // console.log('delte res', res)
        this.selected = []
        this.ids = []
        eventBus.$emit('snackbar', {
          snackbarColor: "#31A274",
          snackbarText: `${res.data.message}`,
        })
        this.page = 1
        this.searchPayload = ''
        this.getPaged()
      }).catch(err => {
        console.log(err)
        this.loading = false
        eventBus.$emit('snackbar', {
          snackbarColor: "#FF5252",
          snackbarText: "Something Went Wrong!",
        })
      })
    },
    getPaged () {
      if (this.statusfilter) {
        this.filterTenant()
        return
      }
      const payload = {
        search: this.searchPayload,
        page: this.page
      }
      this.getTenantList(payload)
    },
    getTenantAnalytics() {
      const analytics = store.getters.analytics
      if (analytics.totalTenant > 0) {
        this.analytics = analytics
      } else {
        store.dispatch('getTenantAnalytics')
        .then(res => {
          this.analytics = res.data.data
        }).catch(err => {
          console.log(err)
          eventBus.$emit('snackbar', {
            snackbarColor: "#FF5252",
            snackbarText: "Something Went Wrong!",
          })
        })
      }
    },
    filterTenant () {
      if (this.filter === 'All') {
        this.statusfilter = false
        const payload = {
          search: '',
          page: this.page
        }
        this.getTenantList(payload)
      } else {
        this.statusfilter = true
        this.loading = true
        const payload = {
          status: this.filter.toLowerCase(),
          page: this.page
        }
        store.dispatch('getTenantsFilterStatus', payload)
        .then(res => {
          this.getData(res.data.data.branches.data)
          this.loading = false
        }).catch(err => {
          console.log(err)
          eventBus.$emit('snackbar', {
            snackbarColor: "#FF5252",
            snackbarText: "Something Went Wrong!",
          })
        })
      }
    },
    getAllTenants() {
      const tenants = store.getters.allTenants
      if (tenants.length) {
        this.allTenant = tenants
        this.getData(tenants)
        this.loading = false
      } else {
        store.dispatch('getAllTenants')
        .then(res => {
          // console.log('get all teanants',res.data.data.tenants.last_page)
          this.pageCount = res.data.data.tenants.last_page
          this.allTenant = res.data.data.tenants.data
          this.getData(this.allTenant)
          this.loading = false
        }).catch(err => {
          console.log(err)
          this.loading = false
          eventBus.$emit('snackbar', {
            snackbarColor: "#FF5252",
            snackbarText: "Something Went Wrong!",
          })
        })
      }
    },
    // for Data Table
    getData(data) {
      this.credentials = []
      data.forEach((item, index) => {
        const date = item.last_order_date_time ? this.$date(item.last_order_date_time) : 'None'
        const credential = {
          name: item.tenant.name,
          branch: item.name,
          onboarded_by: item.tenant.onboarded_by ? item.tenant.onboarded_by : 'None',
          status: item,
          last_order_date_time: date,
          onboarding_date: item.tenant.onboarding_date,
          tenant: item.tenant.id,
          id: index,
        };
        this.credentials.push(credential);
      })
      this.allTenant = this.credentials
    },
    viewTenant(item) {
      if (this.statusfilter) {
        store.commit('setSingleTenant', item)
        this.$router.push(`/restaurant/${item.tenant}`)
        return
      }
      if (item.id) {
        store.commit('setSingleTenant', item)
        this.$router.push(`/restaurant/${item.id}`)
      }
      // console.log(item)
      // const idx = this.allTenant.indexOf(item)
      // // console.log(this.allTenant[idx])
      // this.singleTenant = this.allTenant[idx]
      // // console.log('single tenant >> ',this.singleTenant)
      // // eventBus.$emit("singleTenant", this.singleTenant);
      
    },
  },
};
</script>
<style>
.search-filter .v-icon {
  font-size: 18px !important;
}
.table-hover {
  background-color: #151d21;
  cursor: pointer
}
.table-hover table tbody tr:hover {
  background: #284841 !important;
}
.table-hover table tbody tr:hover .eyecon{
  color: white !important;
}
</style>
